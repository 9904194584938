import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e6bdac3e = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _45b0e7ea = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _6e50c0f6 = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _571319ff = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _184f7af0 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _3be8ac21 = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _7ec162f0 = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _346dab05 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _54bd236a = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _45bcc761 = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _bd54f48c = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _736fa68c = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _4e0e3c04 = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _127315be = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _5d0251da = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _31bf6650 = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _6767dc03 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _98f7a1c0 = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _09dd903c = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _2d430203 = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _3a55c621 = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _e6bdac3e,
    name: "kits"
  }, {
    path: "/lojas",
    component: _45b0e7ea,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _6e50c0f6,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _571319ff,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _184f7af0,
    name: "motos"
  }, {
    path: "/reset",
    component: _3be8ac21,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _7ec162f0,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _346dab05,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _54bd236a,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _45bcc761,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _bd54f48c,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _736fa68c,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _4e0e3c04,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _127315be,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _5d0251da,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _31bf6650,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _6767dc03,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _98f7a1c0,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _09dd903c,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _2d430203,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _3a55c621,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
